import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Navbar from "../../components/navbar";
import { useNavigate } from "react-router-dom";
import API_URL from "../../config";
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage";

import { app } from "../../utils/firebase";
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

const ProfileForm = () => {
	const [gender, setGender] = useState("");
	const [selectedLanguages, setSelectedLanguages] = useState(["English"]);
	const [user, setUser] = useState({});
	const [skillsKnown, setSkillsKnown] = useState([]);
	const [skillsRequired, setSkillsRequired] = useState([]);
	const [image, setImage] = useState(null);
	const [amount, setAmount] = useState(0);
	const [days, setDays] = useState(0);
	const [loading, setLoading] = useState(true);
	const [userId, setUserId] = useState("");
	const navigate = useNavigate();
	const categories = [
		"Programming & Technology",
		"Hobbies & Crafts",
		"Academics & Education",
		"Languages & Linguistics",
		"Business & Entrepreneurship",
		"Fitness & Wellness",
		"Music & Performing Arts",
		"Technology & Gadgets",
		"Personal Development",
		"Other",
	];
	const apiUrl = API_URL;

	const allLanguages = [
		{ code: "en", name: "English" },
		{ code: "es", name: "Spanish" },
		{ code: "hi", name: "Hindi" },
		{ code: "bn", name: "Bengali" },
		{ code: "te", name: "Telugu" },
		{ code: "ta", name: "Tamil" },
		{ code: "mr", name: "Marathi" },
		{ code: "gu", name: "Gujarati" },
		{ code: "kn", name: "Kannada" },
		{ code: "ur", name: "Urdu" },
		{ code: "pa", name: "Punjabi" },
		{ code: "ml", name: "Malayalam" },
		{ code: "or", name: "Odia" },
		{ code: "as", name: "Assamese" },
		{ code: "ka", name: "Kannada" },
		{ code: "ne", name: "Nepali" },
		{ code: "sd", name: "Sindhi" },
		{ code: "zh", name: "Chinese" },
		{ code: "fr", name: "French" },
		{ code: "de", name: "German" },
		{ code: "ja", name: "Japanese" },
		{ code: "ko", name: "Korean" },
		{ code: "ru", name: "Russian" },
		{ code: "ar", name: "Arabic" },
		{ code: "pt", name: "Portuguese" },
		{ code: "it", name: "Italian" },
		{ code: "nl", name: "Dutch" },
		{ code: "tr", name: "Turkish" },
	];

	const handleAddLanguage = () => {
		setSelectedLanguages((prevLanguages) => [...prevLanguages, "English"]);
	};

	const handleLanguageChange = (e, index) => {
		const updatedLanguages = [...selectedLanguages];
		updatedLanguages[index] = e.target.value;
		setSelectedLanguages(updatedLanguages);
	};

	const handleSkillChange = (e, index, field) => {
		const updatedSkills = [...field];
		updatedSkills[index] = {
			...updatedSkills[index],
			[e.target.name]: e.target.value,
		};
		field === skillsKnown
			? setSkillsKnown(updatedSkills)
			: setSkillsRequired(updatedSkills);
	};

	const handleCategoryChange = (e, index, field) => {
		const updatedSkills = [...field];
		updatedSkills[index] = {
			...updatedSkills[index],
			category: e.target.value,
		};
		field === skillsKnown
			? setSkillsKnown(updatedSkills)
			: setSkillsRequired(updatedSkills);
	};

	function converttobase64(file) {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			// Retrieve the JWT token from localStorage
			const token = localStorage.getItem("jwt");
			if (!token) {
				// Handle the case where the token is not present
				console.error("JWT token not found in localStorage");
				navigate("/register");
				return;
			}
			{
				console.log(
					"Skillsss reqqq--",
					skillsRequired,
					"amountt--",
					amount,
					"gender ---",
					gender
				);
			}
			if (skillsRequired.length === 0) {
				alert("Skills Required must be given");
			} else if (gender === "") {
				alert("Please Select your gender");
			} else {
				// Make a request to the backend with the JWT token in the Authorization header
				const user_id = user._id;
				const response = await axios.post(`${apiUrl}/profileDetails`, {
					headers: { Authorization: `Bearer ${token}` },
					user_id,
					gender,
					selectedLanguages,
					skillsKnown,
					skillsRequired,
					image,
					amount,
					days,
				});

				console.log("pd res", response);
				if (response.status === 200) {
					alert("Profile Details Updated Successfully");
					navigate(`/dashboard`);
				}
			}
		} catch (error) {
			console.error("Failed to fetch user details:", error);
		}
	};

	useEffect(() => {
		setLoading(true);
		const fetchUserDetails = async () => {
			try {
				const token = localStorage.getItem("jwt");
				if (!token) {
					console.error("JWT token not found in localStorage");
					navigate("/register");
					return;
				}

				const response1 = await axios.get(`${apiUrl}/dashboard/userId`, {
					headers: { Authorization: `Bearer ${token}` },
				});
				console.log("res ", response1);
				if (!response1.data.userId) {
					console.log("No user ID found");
				}
				setUserId(response1.data.userId);

				const response = await axios.get(
					`${apiUrl}/dashboard?userId=${response1.data.userId}`
				);
				console.log("res ", response);
				console.log("res ", response.data.user.image);
				console.log("res from edit profile", response.data.user);
				setUser(response.data.user);
				setGender(response.data.user.gender || ""); // Default to empty string if undefined
				setImage(response.data.user.image || null);
				setAmount(
					response.data.user.amount !== 0 ? response.data.user.amount : 0
				);
				setDays(response.data.user.days !== 0 ? response.data.user.days : 0);

				// Set selected languages from the API response, or default to ['en'] if undefined
				setSelectedLanguages(response.data.user.selectedLanguages || ["en"]);

				// Set skillsKnown and skillsRequired from the API response, or default to empty arrays if undefined
				setSkillsKnown(response.data.user.skillsKnown || []);
				setSkillsRequired(response.data.user.skillsRequired || []);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch user details:", error);
			}
		};

		fetchUserDetails();
	}, [navigate]);
	const handleRemoveLanguage = (indexToRemove) => {
		const updatedLanguages = selectedLanguages.filter(
			(_, index) => index !== indexToRemove
		);
		setSelectedLanguages(updatedLanguages);
	};

	const handleImageUpload = async (e) => {
		const file = e.target.files[0];
		if (!file.type.startsWith("image/")) {
			alert("Please select an image file.");
			return;
		}

		// Check if the selected image size is less than 2MB
		if (file.size > MAX_FILE_SIZE) {
			alert("Please select an image less than 2MB in size.");
			return;
		}
		if (file) {
			try {
				const userId = user._id;
				const fileName = `${userId}`;
				const storage = getStorage();
				const storageRef = ref(storage, fileName);
				const uploadTask = uploadBytesResumable(storageRef, file);

				uploadTask
					.then(async (snapshot) => {
						// Image uploaded successfully, get the download URL
						const downloadURL = await getDownloadURL(snapshot.ref);
						setImage(downloadURL);
					})
					.catch((error) => {
						// Handle unsuccessful upload
						console.error("Error uploading image to Firebase Storage:", error);
					});
			} catch (error) {
				console.error("Error uploading image to Firebase Storage:", error);
			}
		}
	};

	return loading ? (
		<div>
			<CustomLoader>
				<LoaderSpinner />
			</CustomLoader>
		</div>
	) : (
		<PageContainer>
			<Navbar />
			<FormContainer>
				<FormTitle>Profile Details</FormTitle>
				<form onSubmit={handleSubmit}>
				<div className="mb-8 p-6 bg-white rounded-lg shadow-sm">
  <SectionTitle>
    Gender
  </SectionTitle>
  
  <div className="flex items-center space-x-6">
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="radio"
        value="Male"
        checked={gender === "Male"}
        onChange={() => setGender("Male")}
        className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
      />
      <span className="ml-2 text-gray-700">Male</span>
    </label>

    <label className="inline-flex items-center cursor-pointer">
      <input
        type="radio"
        value="Female"
        checked={gender === "Female"}
        onChange={() => setGender("Female")}
        required
        className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
      />
      <span className="ml-2 text-gray-700">Female</span>
    </label>
  </div>
</div>

					{/* Languages Known */}
					<SectionTitle>Languages Known:</SectionTitle>

					{selectedLanguages.map((language, index) => (
						<FormSection key={index}>
							<Holder>
								<FormLabel>Language {index + 1}:</FormLabel>
								{index != 0 && (
									<Button onClick={() => handleRemoveLanguage(index)}>
										❌
									</Button>
								)}
							</Holder>
							<FormSelect
								required
								value={language}
								onChange={(e) => handleLanguageChange(e, index)}
							>
								<option value="" disabled>
									Select Language
								</option>
								{allLanguages.map((lang) => (
									<option key={lang.code} value={lang.name}>
										{lang.name}
									</option>
								))}
							</FormSelect>
						</FormSection>
					))}

					<FormButton type="button" onClick={handleAddLanguage}>
						Add Language
					</FormButton>
					<br />

					<FormSection>
						<SectionTitle>Skills Known:</SectionTitle>
						{skillsKnown.map((skill, index) => (
							<div key={index}>
								<Holder>
									<h3>Skill {index + 1}:</h3>
									<Button
										onClick={() =>
											setSkillsKnown(skillsKnown.filter((_, i) => i !== index))
										}
									>
										❌
									</Button>
								</Holder>

								<FormSelect
									required
									value={skill.category}
									onChange={(e) => handleCategoryChange(e, index, skillsKnown)}
								>
									<option value="" disabled>
										Select Category
									</option>
									{categories.map((category) => (
										<option key={category} value={category}>
											{category}
										</option>
									))}
								</FormSelect>
								<FormInput
									type="text"
									name="skill"
									value={skill.skill || ""}
									onChange={(e) => handleSkillChange(e, index, skillsKnown)}
									placeholder="Enter Skill"
								/>
							</div>
						))}
						<FormButton
							type="button"
							onClick={() =>
								setSkillsKnown([...skillsKnown, { category: "", skill: "" }])
							}
						>
							Add Skill
						</FormButton>
					</FormSection>

					{/* Skills Required */}
					<FormSection>
						<SectionTitle>Skills Required:</SectionTitle>
						{skillsRequired.map((skill, index) => (
							<FormSection key={index}>
								<Holder>
									<h3>Skill {index + 1}:</h3>
									<Button
										onClick={() =>
											setSkillsRequired(
												skillsRequired.filter((_, i) => i !== index)
											)
										}
									>
										❌
									</Button>
								</Holder>
								<FormSelect
									required
									value={skill.category}
									onChange={(e) =>
										handleCategoryChange(e, index, skillsRequired)
									}
								>
									<option value="" disabled>
										Select Category
									</option>
									{categories.map((category) => (
										<option key={category} value={category}>
											{category}
										</option>
									))}
								</FormSelect>
								<FormInput
									type="text"
									name="skill"
									value={skill.skill || ""}
									onChange={(e) => handleSkillChange(e, index, skillsRequired)}
									placeholder="Enter Skill"
									required
								/>
							</FormSection>
						))}
						<FormButton
							type="button"
							onClick={() =>
								setSkillsRequired([
									...skillsRequired,
									{ category: "", skill: "" },
								])
							}
						>
							Add Skill
						</FormButton>
					</FormSection>

					{/* money and time */}
					<label htmlFor="amount">
						<SectionTitle>Skill Coins per course</SectionTitle>
					</label>
					<input
						type="number"
						id="amount"
						value={amount}
						onChange={(e) => {
							setAmount(parseInt(e.target.value, 10));
							console.log(typeof parseInt(e.target.value));
							console.log(parseInt(e.target.value));
						}}
					/>
					{/* <label htmlFor="days">Days</label>
					<input
						type="number"
						id="days"
						value={days}
						onChange={(e) => setDays(parseInt(e.target.value, 10))}
					/> */}
					{/* Image Upload */}
					<FormSection>
						<SectionTitle>Upload Image:</SectionTitle>
						{image && (
							<img
								src={image}
								style={{ height: "200px", width: "300px" }}
								alt="Uploaded"
							/>
						)}
						<FormInput
							type="file"
							onChange={handleImageUpload}
							accept="image/*"
							maxLength={MAX_FILE_SIZE}
							{...(image === null && { required: true })}
						/>
					</FormSection>

					{/* Submit Button */}
					<SubmitButton type="submit">Submit</SubmitButton>
				</form>
			</FormContainer>
		</PageContainer>
	);
};

export const PageContainer = styled.div`
  padding-top: 80px; // Space for fixed navbar
  min-height: 100vh;
  background-color: #f8f9fa;
`;

export const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    padding: 1rem;
    margin: 0 1rem;
  }
`;

export const FormTitle = styled.h2`
  color: #1a365d;
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
`;

export const FormSection = styled.div`
  margin-bottom: 2rem;
`;

export const SectionTitle = styled.h3`
  color: #2d3748;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const InputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const Label = styled.label`
  display: block;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: ${props => props.variant === 'danger' ? '#FC8181' : '#4299e1'};
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.variant === 'danger' ? '#F56565' : '#3182ce'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AddButton = styled(Button)`
  background-color: transparent;
  color: #4299e1;
  border: 1px solid #4299e1;

  &:hover {
    background-color: #ebf8ff;
  }
`;

export const RemoveButton = styled.button`
  color: #FC8181;
  padding: 0.25rem 0.5rem;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #F56565;
  }
`;

export const ImagePreview = styled.div`
  margin: 1rem 0;
  text-align: center;

  img {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Spinner = styled.div`
  border: 4px solid rgba(66, 153, 225, 0.2);
  border-top: 4px solid #4299e1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ErrorText = styled.p`
  color: #E53E3E;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

export const SkillContainer = styled.div`
  border: 1px solid #e2e8f0;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const AmountInput = styled(Input)`
  max-width: 200px;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const CustomLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export const LoaderSpinner = styled.div`
  border: 4px solid rgba(66, 153, 225, 0.1);
  border-top: 4px solid #4299e1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

// Form Input Components
export const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: #f8fafc;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
    background-color: white;
  }

  &::placeholder {
    color: #94a3b8;
  }

  &[type="file"] {
    padding: 0.5rem;
    background-color: white;
  }
`;

// Form Select Component
export const FormSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background-color: #f8fafc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
    background-color: white;
  }

  option {
    background-color: white;
    color: #1a202c;
  }
`;

// Layout Components
export const Holder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

export const FormLabel = styled.label`
  font-weight: 500;
  color: #4a5568;
  margin: 0.5rem;
  font-size: 0.875rem;
`;

// Button Components
export const FormButton = styled.button`
  background-color: #4299e1;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  margin-top: 1rem;

  &:hover {
    background-color: #3182ce;
    transform: translateY(-1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SubmitButton = styled(FormButton)`
  background-color: white;
  color: #4299e1;
  border: 2px solid #4299e1;
  margin-top: 2rem;
  font-weight: 600;

  &:hover {
    background-color: #4299e1;
    color: white;
  }
`;



export default ProfileForm;

// </FormSection>

// {/* money and time */}
// <label htmlFor="amount">Amount</label>
// <input type="number" id='amount' value={amount} onChange={(e) => {setAmount(parseInt(e.target.value,10));console.log(typeof (parseInt(e.target.value)));console.log(parseInt(e.target.value))}} />
// <label htmlFor="days">Days</label>
// <input type="number" id='days' value={days} onChange={(e) => setDays(parseInt(e.target.value,10))} />
// {/* Image Upload */}
// <FormGroup>
