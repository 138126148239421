import React from 'react';
import logoImage from "./logo1.jpg"; 
const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white mt-auto">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Section */}
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <img 
                src={logoImage}
                alt="Skill Swap Logo" 
                className="w-16 h-16 rounded-full object-cover"
              />
              <h3 className="text-xl font-semibold text-blue-400">Skill Swap</h3>
            </div>
            <p className="text-sm text-gray-400">
              &copy; {new Date().getFullYear()} Skill Swap Company. All rights reserved.
            </p>
          </div>

          {/* Right Section */}
          <div className="space-y-4 md:space-y-0">
            <div className="flex flex-col md:flex-row md:justify-end md:items-center space-y-4 md:space-y-0 md:space-x-8">
              {/* Social Links */}
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
                <a 
                  href="https://www.facebook.com/profile.php?id=61550428124489"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors duration-200 flex items-center space-x-2"
                >
                  <span className="hover:underline">Facebook</span>
                </a>
                <a 
                  href="https://x.com/manojkumark_cpy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors duration-200 flex items-center space-x-2"
                >
                  <span className="hover:underline">Twitter</span>
                </a>
                <a 
                  href="https://www.instagram.com/skillswap07/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors duration-200 flex items-center space-x-2"
                >
                  <span className="hover:underline">Instagram</span>
                </a>
              </div>

              {/* Contact Link */}
              <div className="md:border-l md:border-gray-700 md:pl-8">
                <a 
                  href="mailto:manojk030303@gmail.com"
                  className="text-gray-400 hover:text-blue-400 transition-colors duration-200 flex items-center space-x-2"
                >
                  <span className="hover:underline">Contact</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;