import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import API_URL from '../../config';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });
      const token = response.data.token;
      if (token) {
        localStorage.setItem('jwt', token);
        navigate('/dashboard');
      } else {
        alert('Wrong email or password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Wrong Email or password');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgetPassword = async () => {
    if (email === '') {
      alert('Please enter the email and click the forget password');
    } else {
      try {
        const response = await axios.post(`${API_URL}/forgot-password`, { email });
        alert(response.data.error);
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to process forget password request');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50">
      <Navbar />
      <div className="flex items-center justify-center px-4 py-32 md:py-40 min-h-[84vh]">
        <div className="w-full max-w-md">
          <div className="bg-white rounded-2xl shadow-xl p-8 transform hover:scale-[1.01] transition-all duration-300">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Welcome Back</h2>
            <form onSubmit={handleLogin} className="space-y-6">
              <div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                />
              </div>
              <div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full py-3 bg-blue-600 text-white rounded-lg font-medium
                  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                  transform hover:-translate-y-0.5 transition-all duration-150
                  ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                {isLoading ? 'Signing in...' : 'Sign In'}
              </button>
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={handleForgetPassword}
                  className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                >
                  Forgot Password?
                </button>
                <Link
                  to="/register"
                  className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200"
                >
                  Create Account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;