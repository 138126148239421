import React, { useState } from 'react';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import axios from "axios";
import API_URL from "../../config";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null); // Clear previous status
  
    try {
      if (name && email && msg) {
        const response = await axios.post(`${API_URL}/contact-mail`, {
          name,
          email,
          msg,
        });
        console.log(response.data)
        if (response.data.error != 'The mail has been sent successfully ') {
          setSubmitStatus('error'); // Backend returned an error
          alert(response.data.error);
        } else {
          setSubmitStatus('success'); // Message sent successfully
          setName(""); // Clear the form
          setEmail("");
          setMsg("");
        }
      } else {
        setSubmitStatus('error');
        alert("All fields are required.");
      }
    } catch (error) {
      setSubmitStatus('error');
      if (error.response?.status === 409) {
        alert("Email already exists");
      } else {
        console.error("Error during submission:", error);
        alert("Submission failed");
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 ">
      <Navbar />
      <div className="px-4 py-16 md:py-24 mt-8 min-h-[80vh]">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden transform hover:scale-[1.01] transition-all duration-300">
            <div className="p-8 md:p-12">
              <h1 className="text-4xl font-bold text-center text-gray-900 mb-8 animate-fade-in">
                Get in Touch
              </h1>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="group">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all duration-200 transform group-hover:scale-[1.01]"
                    placeholder="Your Name"
                  />
                </div>

                <div className="group">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all duration-200 transform group-hover:scale-[1.01]"
                    placeholder="Your Email"
                  />
                </div>

                <div className="group">
                  <textarea
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    required
                    rows="6"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all duration-200 transform group-hover:scale-[1.01]"
                    placeholder="Your Message"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full px-6 py-3 text-lg font-medium text-white bg-blue-600 rounded-lg
                    hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                    transform hover:-translate-y-0.5 transition-all duration-150
                    ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Sending...
                    </span>
                  ) : 'Send Message'}
                </button>

                {submitStatus === 'success' && (
                  <div className="animate-fade-in text-green-600 text-center font-medium">
                    Message sent successfully!
                  </div>
                )}
                
                {submitStatus === 'error' && (
                  <div className="animate-fade-in text-red-600 text-center font-medium">
                    Failed to send message. Please try again.
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <style jsx>{`
        .animate-fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
    </div>
  );
};

export default ContactUs;