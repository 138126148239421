import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import styled, { keyframes } from "styled-components";
import SearchComponent from "../functions/search";
// import {swapRequest} from "./functions/commonFunctions"
import API_URL from "../../config";
import RewardModal from "./rewardModel";

const Dashboard = () => {
	const [user, setUser] = useState({});
	const [feed, setFeed] = useState([]);
	const [notificationCount, setNotificationCount] = useState(0);
	const [searchFeed, setSearchFeed] = useState([]);
	const navigate = useNavigate();
	//   const [showConfetti, setShowConfetti] = useState(true);
	const [showRewardModal, setShowRewardModal] = useState({
		show: false,
		reason: "",
		coinsCount: 0,
	});

	const [loading, setLoading] = useState(true);
	const [skip, setSkip] = useState(0);
	const [isEnd, setIsEnd] = useState(false);
	const apiUrl = API_URL;
	const { userId } = useParams();

	//navbar
	const [open, setOpen] = useState(false);

	const handleToggle = () => {
		setOpen(!open);
	};

	const onSearch = async (searchQuery, selectedFilter) => {
		if (searchQuery === "") {
			setSearchFeed([]);
			return;
		}
		console.log("searchQuery from dash", searchQuery);
		console.log("selectedFilter", selectedFilter);

		const response = await axios.post(`${apiUrl}/dashboard/search`, {
			searchQuery,
			selectedFilter,
		});
		console.log("response from server --search:dash", response.data);
		if (response.data.feed.length === 0) {
			alert("No results found");
		} else {
			setSearchFeed(response.data.feed);
		}
	};
	useEffect(() => {
		{
			console.log("apiUrl", apiUrl);
		}
		// setLoading(true);
		const fetchUserDetails = async (skip) => {
			try {
				// Retrieve the JWT token from localStorage
				// const token = localStorage.getItem("jwt");
				// if (!token) {
				// 	// Handle the case where the token is not present
				// 	console.error("JWT token not found in localStorage");
				// 	navigate("/register");
				// 	return;
				// }

				// Make a request to the backend with the JWT token in the Authorization header
				const response = await axios.get(
					`${apiUrl}/dashboard?skip=${skip}&userId=${userId}`
				);
				console.log("dah response : ", response.data.feed);
				if (response.status === 404) {
					navigate("/register");
				}

				if (response.data.error === "not filled details") {
					navigate("/p");
				}
				if (response.data.feed.length === 0) {
					console.log("no more feed");
					setIsEnd(true);
					setLoading(false);
					setUser(response.data.user);
					setNotificationCount(response.data.user.notifications.length);
					return;
				}
				console.log(
					"response.data.feed",
					response.data.feed,
					response.data.user
				);
				console.log("Current User dah res:", response.data.user);
				setUser(response.data.user);
				setNotificationCount(response.data.user.notifications.length);
				console.log(
					"Notification count",
					response.data.user.notifications.length
				);
				setFeed([...feed, ...response.data.feed]);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch user details:", error);
			}
		};

		const getRewards = async () => {
			try {
				const response = await axios.get(`${apiUrl}/${userId}/rewards`);
				console.log("rewards response", response.data);
				if (response.data.rewarded) {
					setShowRewardModal({
						show: true,
						reason: response.data.reason,
						coinsCount: response.data.rewardCount,
					}); // Show reward modal when rewarded
				}
			} catch (error) {
				console.error("Error fetching rewards:", error);
			}
		};

		fetchUserDetails(skip);
		getRewards();
	}, [navigate, skip]);
	const handleScroll = (e) => {
		e.preventDefault();
		console.log("scrolling");
		const { scrollTop, clientHeight, scrollHeight } = e.target;

		// Check if the user has scrolled to the bottom of the container
		if (scrollTop + clientHeight >= scrollHeight - 30) {
			// Optionally, you can add a threshold value (e.g., 10 pixels) to trigger the action slightly before reaching the absolute bottom
			console.log("feedlength", feed.length);
			setSkip(feed.length); // Trigger the action when reaching the bottom
		}
	};

	return loading ? (
		<div>
			<CustomLoader>
				<LoaderSpinner />
			</CustomLoader>
		</div>
	) : (
		<DashboardContainer>
			<SubNavbar>
				<SubNavbarLinks open={open}>
					{notificationCount > 0 ? (
						<NavLink to={`/${userId}/notifications`}>
							Notifications({notificationCount})
						</NavLink>
					) : (
						<NavLink to={`/${userId}/notifications`}>Notifications</NavLink>
					)}
					<NavLink to="/user/chat">Chats</NavLink>
					<NavLink to={`/user/${user._id}/${user._id}/${user.gender}`}>
						Profile
					</NavLink>
					<NavLink to={`/implements/${user._id}/${user.name}`}>
						Implement
					</NavLink>
					<NavLink to={`/leaderboard/${user._id}`}>LeaderBoard</NavLink>
					<NavLink to={`/game/${user._id}`}>Game</NavLink>
					<NavLink to="/skill-coins">Coins</NavLink>
				</SubNavbarLinks>
				<Hamburger onClick={handleToggle}>{open ? "✕" : "☰"}</Hamburger>
				<LogoutLink to="/logout">Logout</LogoutLink>
			</SubNavbar>
			<BannerContainer>
				<BannerText>Welcome, {user.name}!</BannerText>
				<UserInfo>Email: {user.email}</UserInfo>
			</BannerContainer>
			{showRewardModal.show && (
				<RewardModal
					onClose={() =>
						setShowRewardModal({ show: false, reason: "", coinsCount: 0 })
					}
					reason={showRewardModal.reason}
					coinsCount={showRewardModal.coinsCount}
				/>
			)}
			<SearchComponent onSearch={onSearch} />
			{searchFeed.length > 0 && <h3>Search Results:</h3>}
			<FeedContainer onScroll={handleScroll}>
				{searchFeed.length > 0 &&
					searchFeed.map((feedUser) => (
						<UserDetails key={feedUser._id}>
							<p>Name: {feedUser.name}</p>
							<SkillsList>
								<p>Skills Known:</p>
								<ul>
									{feedUser.skillsKnown.map((skill, index) => (
										<li key={index}>
											{skill.category}: {skill.skill}
										</li>
									))}
								</ul>
							</SkillsList>
							<SkillsList>
								<p>Skills Required:</p>
								<ul>
									{console.log("skilssss reqq---", feedUser.skillsRequired)}
									{feedUser.skillsRequired.map((skill, index) => (
										<li key={index}>
											{skill.category}: {skill.skill}
										</li>
									))}
								</ul>
							</SkillsList>
							<ActionButtons>
								<ProfileButton
									to={`/user/${feedUser._id}/${user._id}/${user.gender}`}
								>
									View Profile
								</ProfileButton>
								{/* <SwapButton onClick={(e) => {swapRequest(feedUser._id,user._id)}}>Swap</SwapButton> */}
							</ActionButtons>
						</UserDetails>
					))}
				<hr />
				<br />
				<h3>Feed:</h3>
				<br />
				{feed &&
					feed.map((feedUser) => (
						<UserDetails key={feedUser._id}>
							<p>Name: {feedUser.name}</p>
							<SkillsList>
								<p>Skills Known:</p>
								<ul>
									{feedUser.skillsKnown.map((skill, index) => (
										<li key={index}>
											{skill.category}: {skill.skill}
										</li>
									))}
								</ul>
							</SkillsList>
							<SkillsList>
								<p>Skills Required:</p>
								<ul>
									{console.log("skilssss reqq---", feedUser.skillsRequired)}
									{feedUser.skillsRequired.map((skill, index) => (
										<li key={index}>
											{skill.category}: {skill.skill}
										</li>
									))}
								</ul>
							</SkillsList>
							<ActionButtons>
								<ProfileButton
									to={`/user/${feedUser._id}/${user._id}/${user.gender}`}
								>
									View Profile
								</ProfileButton>
								{/* <SwapButton onClick={(e) => {swapRequest(feedUser._id,user._id)}}>Swap</SwapButton> */}
							</ActionButtons>
						</UserDetails>
					))}
			</FeedContainer>
			{isEnd && <h3>No more Feed</h3>}
		</DashboardContainer>
	);
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DashboardContainer = styled.div`
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
`;

const SubNavbar = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.1);
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 20px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
	}
`;

const NavLink = styled(Link)`
	color: #333;
	margin: 0 15px; /* Decreased margin */
	text-decoration: none;
	transition: color 0.3s;
	position: relative;
	z-index: 3;

	&:hover {
		color: #007bff;
	}

	@media screen and (max-width: 768px) {
		margin: 5px 0; /* Decreased margin */
		font-size: 14px; /* Decreased font size */
	}
`;

const Hamburger = styled.div`
	display: none;
	cursor: pointer;
	font-size: 24px;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 999;
	}
`;

const SubNavbarLinks = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 0; /* Ensure background is below links */

	@media screen and (max-width: 768px) {
		flex-direction: column;
		position: absolute;
		top: ${({ open }) => (open ? "60px" : "-500px")};
		left: 0;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.1);
		padding: 10px;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		transition: top 0.3s ease;
		z-index: 0; /* Ensure background is below links */
		background-color: white;
	}
`;

const LogoutLink = styled(Link)`
	margin-left: auto;
	position: relative;
	z-index: 1;
	padding: 10px 10px; /* Decreased padding */
	background-color: #007bff;
	color: white;
	text-decoration: none;
	border-radius: 5px;
	transition: background-color 0.3s;

	&:hover {
		background-color: #0056b3;
	}
	@media screen and (max-width: 768px) {
		margin-left: 0;
		margin-top: 5px; /* Decreased margin */
	}
`;

const BannerContainer = styled.div`
	background-color: #f5f5f5;
	padding: 30px;
	border-radius: 15px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
	animation: ${fadeIn} 0.5s ease;
	text-align: center;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const BannerText = styled.h1`
	color: #333;
	font-size: 24px; /* Decreased font size */
	margin: 0;
	font-weight: bold; /* Added bold */
	font-style: italic; /* Added italics */
`;

const UserInfo = styled.div`
	margin-top: 5px; /* Decreased margin */
	color: #666;
	font-size: 16px; /* Decreased font size */
	font-style: italic; /* Added italics */
`;

const FeedContainer = styled.div`
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(5px);
	padding: 20px;
	border-radius: 5px;
	margin-bottom: 20px;
	max-height: 60vh; /* Set a maximum height for the container */
	overflow-y: auto; /* Enable vertical scrolling */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	/* Hide scrollbar for Chrome, Safari, and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
`;

const UserDetails = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px; /* Decreased padding */
	border-radius: 5px;
	margin-bottom: 20px;
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(5px);
	font-size: 14px; /* Decreased font size */
`;
const SkillsList = styled.div`
	ul {
		list-style: none;
		padding: 0;
		margin: 10px 0;
	}

	li {
		margin: 5px 0;
		font-size: 12px; /* Decreased font size */
		font-style: italic; /* Added italics */
		font-weight: bold; /* Added bold */
	}
`;

const ActionButtons = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 10px; /* Decreased margin */
`;

const ProfileButton = styled(Link)`
	padding: 5px 10px; /* Decreased padding */
	background-color: #007bff;
	color: white;
	text-decoration: none;
	border-radius: 5px;
	transition: background-color 0.3s;

	&:hover {
		background-color: #0056b3;
	}
`;

// const LogoutLink = styled(Link)`
//   display: block;
//   text-align: center;
//   background-color: #ff6347;
//   color: white;
//   padding: 10px;
//   text-decoration: none;
//   border-radius: 5px;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #ff6347;
//   }
// `;

//loader
const CustomLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

const LoaderSpinner = styled.div`
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default Dashboard;
