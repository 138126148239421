import React from 'react';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 ">
      <Navbar />
      <div className="px-4 py-16 md:py-24 min-h-[82.43vh]">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="relative p-8 md:p-12">
              {/* Animated background elements */}
              <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-white opacity-50"></div>
              
              <div className="relative z-10">
                <h1 className="text-4xl md:text-5xl font-bold text-center text-gray-900 mb-8 animate-title">
                  About Us
                </h1>
                
                <div className="space-y-8">
                  <div className="transform hover:scale-[1.02] transition-all duration-300 bg-white rounded-xl p-6 shadow-sm">
                    <h2 className="text-2xl font-semibold text-blue-600 mb-4">Our Story</h2>
                    <p className="text-lg text-gray-600 leading-relaxed">
                      Founded by a group of dedicated Computer Science major students,
                      Skill Swap is driven by the vision to create a platform that
                      redefines online learning and skill sharing.
                    </p>
                  </div>

                  <div className="transform hover:scale-[1.02] transition-all duration-300 bg-white rounded-xl p-6 shadow-sm">
                    <h2 className="text-2xl font-semibold text-blue-600 mb-4">Our Mission</h2>
                    <p className="text-lg text-gray-600 leading-relaxed">
                      Our mission is to connect learners worldwide and empower them
                      to unlock their potential through collaborative learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <style jsx>{`
        .animate-title {
          animation: slideDown 0.8s ease-out forwards;
        }
        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default AboutUs;